@import './placeholder';

@mixin backColor($backgroundColor, $selectable: false) {
  transition: background-color 0.75s, color 0.25s, border-color 0.75s;
  @if $selectable {
    color: #fff;
    border-color: #fff !important;
    background-color: rgba($backgroundColor, 0.85);
  } @else {
    background-color: rgba(#fff, 0.95);
  }

  &:hover {
    @if $selectable {
      background-color: #fff;
      color: $backgroundColor;
      border-color: $backgroundColor !important;
      cursor: pointer;
    } @else {
      background-color: $backgroundColor;
    }
  }
}

@mixin color-transition($color: #000, $time: 0.75s) {
  color: $color;
  transition: color $time;
}

@mixin container($width: 100%) {
  @extend %push-down;
  border: $bdr;
  border-radius: $border-curve;
  padding: 20px 2.5% 0 2.5%;
  min-height: 250px;
  position: relative;
  width: $width;
  &.align-start {
    margin: 0 2.5% 20px 0;
  }

  &.align-end {
    margin: 0 0 20px 2.5%;
  }
}

@mixin skewCalibrate($deg1: 0deg, $deg2: 0deg) {
  transform: skew($deg1, $deg2);
  &-calibrate {
    transform: skew(-$deg1, -$deg2);
  }
}
