@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Open+Sans&display=swap');

h1,
h2 {
  margin: 0 0 0 0;
  display: block;
  align-self: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
}

p {
  margin: 0 0 20px 0;
  display: block;
  align-self: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
}

.bold {
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.usa {
  @extend .bold;
  text-decoration: underline;
  display: inline;
}

@keyframes color-change {
  0% {
    color: red;
    -webkit-text-decoration-color: blue; /* Safari */
    text-decoration-color: blue;
  }
  50% {
    color: blue;
    -webkit-text-decoration-color: red; /* Safari */
    text-decoration-color: red;
  }

  100% {
    color: red;
    -webkit-text-decoration-color: blue; /* Safari */
    text-decoration-color: blue;
  }
}
