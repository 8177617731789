.row {
  flex-direction: row;
}

.push-down {
  margin-bottom: 20px;
}

@for $i from 1 through 100 {
  .col-#{$i} {
    flex: $i;
    flex-basis: 0;
    flex-grow: #{$i};
    flex-shrink: 0;
  }
}

.center {
  justify-content: center;
}

.align-end {
  align-self: flex-end;
}

.sibling {
  width: 95%;
}
