@import '../utils/import';
.cta {
  &-bucket {
    @include container(95%);
    @include backColor($confirm, true);

    //@include skewCalibrate(-10deg);
    text-align: center;
    padding: 20px 2.5% 0 2.5%;
    min-height: 150px;
    & svg {
      align-self: center;
      font-size: 36px;
    }
  }
}
