@import '../utils/import';

.footer {
  background-color: $blue-mid;
  border-top: 1px solid $border;
  padding: 20px 2.5% 0 2.5%;
  &-bottom p {
    color: #fff;
    text-align: right;
    align-self: center;
    flex: 0;
    margin: auto;
    @media only screen and (max-width: 655px) {
      & {
        font-size: 12px;
      }
    }
  }
  &-social-icons {
    max-width: 200px;
  }

  & .cta-bucket {
    align-self: center;
    margin: 0 0 20px 0;
    max-width: 375px;
    min-height: 100px;
    padding: 0 2.5%;
    & p {
      margin: 0 0 0 0;
    }
  }
}
