@import '../utils/import';

.products {
  &-card {
    @include container(98%);
    @include backColor($blue-light, $selectable: true);
    position: relative;
    text-align: center;
    overflow: hidden;
    padding-top: 0px;
    transition: all 0.75s;

    @media only screen and (max-width: 1050px) {
      & {
        min-height: 200px;
      }
    }
    & .title-bar {
      flex: 0;
    }

    &-summary {
      @media only screen and (max-width: 1050px) {
        & {
          font-size: 12px;
          margin: 0 0 0 0;
        }
      }
    }

    &-content {
      border-radius: $border-curve;
      background-color: rgba(#3574a7, 0.85);
      border: solid 1px #fff;
      padding: 20px 2.5% 0 2.5%;
      height: 20%;
      max-height: 80%;
      margin: auto;
      line-height: 2;

      @media only screen and (max-width: 1050px) {
        & {
          max-height: 95%;
          height: 100%;
        }

        & .bold {
          font-size: 20px;
          margin: 0 0 0 0;
        }
      }
    }

    &:hover .products-card-content {
      color: #fff;
      background-color: $blue-dark;
    }
  }

  &-back {
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    filter: grayscale(50) blur(1px);

    &-image {
      &-production {
        background-image: url('/images/background-prod.jpg');
      }
      &-safety {
        background-image: url('/images/background-safety.jpg');
      }
    }

    &.prod {
      background-image: url('/images/background-prod.jpg');
    }

    &.safety {
      background-image: url('/images/background-safety.jpg');
    }
  }

  &-bar {
    text-align: center;
    &.products-header {
      @extend %push-down;
      & h2 {
        color: #fff;
        justify-self: center;
        align-content: center;
        margin: 0 0 0 0;
      }
    }
    &.products-footer {
      @extend %push-down;
      @include backColor($blue-light, $selectable: true);
      border-radius: $border-curve;
    }
  }

  &-container {
    @include container(98%);
    background-color: $blue-dark;
    transition: height 0.75s;
    margin: 20px auto !important;
    & h2 {
      @extend %push-down;

      text-align: center;
      color: $grey;
    }

    & .title-bar {
      background-color: #fff;
      @extend %push-down;
      & h2,
      h3 {
        margin: 0 0 0 0;
        justify-content: center;
        color: $blue-dark;
      }
    }
  }
}
