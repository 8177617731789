@import '../utils/import';

.card {
  &-container {
    @include container(98%);
    @extend %push-down;
    @include backColor(#fff);
    @include color-transition($grey);
    padding-bottom: 20px;
    transition: background-color 1s, color 0.75s;
    align-items: stretch;

    &:hover {
      color: #000;

      .usa {
        animation: color-change 2s infinite;
      }
    }

    .title-bar {
      @extend %push-down;
      min-height: 50px;
      padding: 5px 0;
      h2 {
        align-self: center;
        justify-content: center;
      }
    }

    ul {
      padding: 0 0 0 1.5%;
      ul {
        padding: 0 0 0 2.5%;
      }
    }

    p {
      line-height: 2;
      margin: 0 0 0 0;
      justify-content: center;
      display: inline-block;
    }
  }

  &-list-item {
    @extend %push-down;
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    transform: scale(1);
    transition: transform 0.75s;

    & svg {
      @extend %display-together;
      width: 20%;
      margin: 7.5px 2.5% 0 0;
      vertical-align: top;
    }
    & p {
      @extend %display-together;
      width: 80%;
      margin: 0 0 0 0;
    }

    &:hover {
      transform: scale(1.01);
    }
  }
}
