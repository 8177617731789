@import '../utils/import';

.social-icon-container {
  @extend %push-down;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  align-self: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.75s, border-color 0.75s;
  border: 1px solid transparent;
  svg {
    align-self: center;
    color: #fff;
    font-size: 1.5rem;
  }

  &:hover {
    border-color: #fff;
    &.linkedin {
      background-color: #0077b5;
    }
    &.facebook {
      background-color: #3b5998;
    }
    &.instagram {
      @extend %instagram;
    }
  }
}
