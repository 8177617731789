.safari-fix {
  .hero-product-container {
    min-height: 700px;
  }

  .hero-product-container.hero-no-img {
    min-height: 250px;
  }

  .hero-product-gallery-select img {
    max-height: 150px;
  }

  .hero-product-content {
    max-height: 80px;
  }

  .hero-product-gallery-image {
    justify-content: center;
    img {
      max-height: 300px;
    }
  }

  .hero-product-gallery-select {
    justify-content: center;
  }

  // .nav-products-container {
  //   margin: 0 0 0 0;
  // }

  .products-open {
    //height: -webkit-fill-available;

    .col-1 {
      justify-content: center;
    }

    .button {
      padding-left: 25%;
    }

    .title-bar {
      max-height: 50px;
    }
  }
  .card-container-list {
    min-height: 575px;

    .title-bar {
      max-height: 50px;
      padding: 5px 2.5%;
    }

    .col-1 {
      justify-content: center;
    }
  }
  .card-list-item {
    margin: 0 0 0 0;
    p {
      font-size: 15px;
    }
  }

  h2 {
    font-size: 20px;
  }
  .page-about .safari-card .card-container {
    min-height: 500px;
  }

  @media only screen and (max-width: 655px) {
    .safari-card {
      min-height: 575px;

      .card-container-list {
        min-height: 650px;
      }

      .col-1 {
        max-height: 80px;
      }
    }
    .card-container {
      font-size: 12px;
      min-height: 350px;
      .title-bar {
        max-height: 50px;
        padding: 10px 2.5%;
      }
    }

    .card-container-list {
      min-height: 550px;
      .col-1 {
        max-height: 80px;
      }
    }

    .hero-fade-message p.title {
      font-size: 60px;
    }
  }

  .products-card {
    @media only screen and (max-width: 1050px) {
      & {
        min-height: 200px;
      }
    }
  }

  .products-open {
    min-height: 900px;
    @media only screen and (max-width: 1050px) {
      & {
        min-height: 1200px;
      }
    }
  }

  .products-container {
    @media only screen and (max-width: 1050px) {
      & .col-3 {
        min-height: 900px;
      }
    }
  }

  &.mobile-nav-container {
    .fa-chevron-down {
      //margin: 0 0 0 5%;
    }

    .mobile-nav-bars-x {
      align-self: flex-end;
      width: 40px;
      height: 40px;
    }

    .logo-img {
      margin-left: 5px;
    }
  }
}
