@import '../utils/import';

.hero-product {
  &-container {
    @extend %push-down;
    position: relative;
    min-height: 33vh;
    overflow: hidden;
    border-radius: $border-curve;
    border: solid 1px $blue-dark;
  }
  &-title {
    max-height: 60px;

    @extend %push-down;
    & h2 {
      @extend %title-bar;
      padding: 5px 2.5%;
    }
  }

  &-content {
    position: relative;
    z-index: 2;
    padding: 20px 2.5% 0 2.5%;
  }

  &-back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    &-filter {
      position: absolute;
      background-color: rgba($blue-light, 0.65);
      width: 100%;
      height: 100%;
      z-index: 10;
    }
    &-image {
      background-position: center;
      background-size: cover;
      filter: blur(10px);
    }
  }

  &-gallery {
    &-container {
      position: relative;
      z-index: 10;
      @extend %push-down;
    }
    &-selector {
      padding: 0 2.5%;
    }
    &-select {
      cursor: pointer;

      & img {
        width: 50%;
        align-self: center;
        border: 1px solid transparent;
        transition: border-color 1sec filter 1sec;
        filter: grayscale(75%);
      }
      &:hover img {
        border-color: $blue-light;
        filter: grayscale(0%);
      }
    }
    &-image {
      @extend %push-down;
      & img {
        display: flex;
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}
