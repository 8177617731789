@import '../utils/import';

html {
  //font-size: 62.5%;
}

body {
  @extend %flex-layout;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;

  & * {
    @extend %flex-layout;
  }
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.content {
  &-section {
    padding-top: 20px;
  }
  &-container {
    min-height: 100vh;
    & > div:nth-child(2) {
      margin-top: 80px;
    }
  }
  &-wrapper {
    padding-top: 20px;
    // background: $blue-dark;
    // background: linear-gradient(180deg, rgba($blue-dark, 1) 0%, rgba(0, 0, 0, 1) 75%);
    background: $blue-light;
    background: linear-gradient(180deg, rgba($blue-dark, 1) 0%, rgba(#fff, 1) 75%);
    &.products-path {
      background: $blue-light;
      background: linear-gradient(180deg, rgba($blue-dark, 1) 0%, rgba(#fff, 1) 75%);
    }
    & > div {
      padding: 0 2.5%;
      max-width: 1500px;
      align-self: center;
    }
  }
}

.title-bar {
  @extend %title-bar;
}
