.mobile-nav {
  &-logo {
    margin: 0 0 20px 0;
    .logo-img {
      height: 80px;
      padding: 5px 0;
      & > div {
        height: 80px;
      }
    }
  }

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    & .nav-products {
      display: none;

      &-body {
        position: relative;
        border-width: 0px;
        border-radius: 0px;
        max-width: 100%;
        padding: 0;
      }
      &-container {
        max-height: 100px;
      }
      &.open {
        display: flex;
        max-height: 700px;

        & .nav-products-body {
          display: flex;
        }
      }
    }

    & .nav {
      margin: 0 0 0 0;

      & a {
        max-height: 80px;
      }

      &-products-header.header-nav-item {
        height: 80px;

        & p {
          text-align: right;
        }
      }

      & .header-nav-item {
        height: 80px;
        margin: 0 auto 20px auto;
        width: 95%;
      }
    }

    & .fa-chevron-down {
      align-self: flex-start;
      margin: 0 0 0 10%;
    }
  }
  &-bars {
    position: absolute;
    top: 25px;
    width: 30px;
    z-index: 100;
    margin-right: 0;
    right: 5%;
    &-x {
      align-self: flex-end;
      width: 25px !important;
    }
  }
}
