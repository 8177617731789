@import '../utils/import';
@font-face {
  font-family: 'Vtksragwaris';
  src: url('/fonts/vtksragwaris.eot');
  src: url('/fonts/vtksragwaris.eot?#iefix') format('embedded-opentype'),
    url('/fonts/vtksragwaris.woff2') format('woff2'), url('/fonts/vtksragwaris.woff') format('woff'),
    url('/fonts/vtksragwaris.ttf') format('truetype'),
    url('/fonts/vtksragwaris.svg#svgFontName') format('svg');
}

.hero-banner {
  &-container {
    width: 100%;
    min-height: 40vh;
    position: relative;
    border-bottom: 1px solid $border;
    & .react-reveal {
      position: relative;
      z-index: 20;
    }
    & .logo-img {
      padding: 20px 0 0 0;

      & > div {
        max-height: 150px;
      }
    }
    & .title-bar {
      background-color: #fff;
      @extend %push-down;
      & h2,
      h3 {
        margin: 0 0 0 0;
        flex: 0;
        color: $blue-dark;
      }
    }
  }

  &-image {
    &-container {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &-filter {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($blue-dark, 0.75);
      z-index: 10;
    }

    background-image: url('/images/background-banner.jpg');
    background-position: center;
    background-size: cover;
    // &:before {
    //   content: '';
    //   width: 0;
    //   height: 0;
    //   border-bottom: 40vh solid $blue-dark;
    //   border-right: 40vh solid transparent;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
    // &:after {
    //   content: '';
    //   width: 0;
    //   height: 0;
    //   border-bottom: 40vh solid $blue-dark;
    //   border-left: 40vh solid transparent;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    // }
  }
}

.hero-fade-message {
  position: relative;
  z-index: 20;
  & p {
    color: #fff;
    text-align: center;
    font-size: 65px;

    &.title {
      text-transform: uppercase;
    }
    &.vxt {
      font-family: 'Vtksragwaris';
    }
  }
}
