@import '../utils/import';

.header {
  position: fixed;
  z-index: 100;
}

.nav {
  border-bottom: 1px solid $border;
  background-color: #fff;
  min-height: 80px;

  &-products {
    position: relative;

    &-body {
      position: absolute;
      background-color: #fff;
      display: none;
      border: 1px solid $border;
      border-top-color: #fff;
      max-width: 650px;
      text-align: center;
      padding: 20px 2.5%;
      border-radius: 0 0 0 20px;
      top: -1px;
      &.open {
        display: block;
      }

      & .header-nav-item {
        height: 50px;
        transition: background-color 0.75s;

        &:hover,
        &.active {
          @extend %title-bar;
          & p {
            color: #fff;
          }
        }
        & p {
          margin: auto 0 auto 2.5%;
          flex: 0;
          color: #000;
          font-size: 20px;
        }
      }
    }

    &-container {
      cursor: pointer;
      &.open .nav-products-body {
        display: flex;
      }
      &:hover .nav-products-body {
        //display: flex;
      }
    }

    &-header {
      & svg {
        // align-self: center;
      }
    }
  }

  & .header-nav-item {
    text-align: center;
    margin: auto 5%;
    height: 50px;
    transition: background-color 0.75s;
    &.active {
      @extend %title-bar;
      p {
        color: #fff;
      }
    }
    & p {
      margin: auto;
      align-self: center;
      flex: 0;
      width: 98%;
      color: #000;
      font-size: 20px;
    }
    &:hover {
      @extend %title-bar;
      p {
        color: #fff;
      }
    }
  }
  &-bars {
    align-self: flex-end;
    margin-right: 5%;
    width: 25px !important;
  }
}

.header .logo {
  &-img {
    justify-content: center;
    z-index: 10;
    & > div {
      max-height: 75px;
      @media only screen and (max-width: 1050px) {
        & {
          max-height: 60px;
          margin: 0 0 0 15%;
        }
      }
    }
  }
  &-link {
    position: relative;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    svg path {
      transition: fill 1s;
    }

    &:hover svg path {
      fill: $blue-dark;
    }
  }
}
