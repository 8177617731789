@import './variables';

%flex-layout {
  display: flexbox;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

%contain-elements {
  max-width: 1500px;
  width: 80%;
  margin: 0 auto;
}

%push-down {
  margin: 0 auto 20px auto;
}

%container {
  @extend %push-down;
  border: $bdr;
  border-radius: $border-curve;
  padding: 20px 2.5% 0 2.5%;
  min-height: 250px;
  position: relative;
  width: 98%;
  &.align-start {
    margin: 0 2.5% 20px 0;
  }

  &.align-end {
    margin: 0 0 20px 2.5%;
  }
}

%title-bar {
  background-color: $blue-dark;
  color: #fff;
  border-radius: 0 $border-curve 0 $border-curve;
  text-align: center;
  min-height: 50px;
  justify-content: center;
  align-items: center;
}

%display-together {
  display: inline-block;
}

%instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
