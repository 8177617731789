@import '../utils/import';

th {
  display: table-cell;
  text-align: left;
}
tr {
  flex-direction: row;
}

.table-product {
  background-color: #fff;
  border: $bdr;
  display: block;
  flex: 0;
  text-align: center;

  & thead {
    & th {
      background-color: $blue-light;
      color: #000;
      text-align: center;
      padding: 20px 2.5%;
      border-right: $bdr;
      &:last-child {
        border-right-width: 0px;
      }
      @media only screen and (max-width: 636px) {
        & {
          font-size: 12px;
          padding: 20px 1.5%;
        }
      }
    }
  }

  & td {
    align-self: center;
    border-right: $bdr;
    &:last-child {
      border-right-width: 0px;
    }
  }
  & tr {
    border-bottom: $bdr;
    &:last-child {
      border-bottom-width: 0px;
    }
  }
}

.table-product {
  @extend %push-down;
  & p {
    padding: 20px 0;
    margin: 0 0 0 0;
    width: 98%;

    @media only screen and (max-width: 636px) {
      & {
        font-size: 12px;
      }
    }
  }
}
