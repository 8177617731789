@import '../utils/import';

.button {
  @extend %push-down;
  background-color: $confirm;
  appearance: none;
  border: 2px solid #fff;
  color: #fff;
  max-height: 80px;
  max-width: 80%;
  border-radius: $border-curve;
  transition: background-color 1s, color 1s;
  &:focus {
    outline-width: 0px;
  }
  &:hover {
    background-color: #fff;
    color: $confirm;
    border-color: $confirm;
  }
  & .row {
    max-height: 80px;
  }
  & p {
    flex: 0;
    margin: auto;
    font-size: 1.5rem;
    font-family: 'Lato', sans-serif;
    padding-right: 25%;
  }
  & svg {
    font-size: 2.5rem;
    align-self: center;
  }
}
