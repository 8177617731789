.slogan-banner {
  width: 95%;
  padding: 0 2.5%;
  position: relative;
  z-index: 10;
  max-width: 1140px;
  h2 {
    padding: 5px 0;
    display: inline-block;
  }
  span {
    display: inline-block;
    flex: 0;
    text-align: left;
    width: 0;
    text-decoration: underline;
  }
}
