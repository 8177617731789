@import '../utils/import';

.body-background-container {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.body-background {
  position: absolute;
  background-image: url('/images/place-holder.jpg');
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: grayscale(100%);
}
.filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba($grey, 0.95);
}
